import React from 'react'
import styled from 'styled-components'
const StyledDiv = styled.div`
width: 100%;
background: transparent;
display: flex;
justify-content: center;
align-items: center;

.mainDiv {
width: 90%;
background: transparent;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 25px 25px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

@media (max-width: 800px){
    flex-direction: column;
    max-width:100%;
}
}

.image-div{
    // max-width:50%;
    background:transparent;
    display: flex;
    justify-content:flex-start;
    align-items:flex-start;
    align-self:flex-start;
    flex-direction: column;
    flex: 1;
}

.buy-box-main{
    // max-width:50%;
    background:transparent;
    display: flex;
    justify-content:center;
    align-items:center;
    flex: 1;
}

.buy-box{
    max-width:80%;
    background:transparent;
    display: flex;
    justify-content:flex-start;
    align-items:flex-start;
    flex-direction: column;
    flex: 1;
    @media (max-width: 800px){
        justify-content:center;
        align-items:center;
    }
}

.image-class{
    width: 100%;
    max-width:100%;
}

.h1-class{
    font-size: 26px;
    font-weight: 700;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.2;
}

@media only screen and (min-width: 769px)
.h1-class {
    font-size: 36px;
}

.span-old-price{
    padding: 5px;
    text-decoration: line-through;
    color: #1c1d1d;
    font-weight: 400;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
    font-size: 16px;

}
.span-new-price{
    padding: 5px;
    color: #1c1d1d;
    font-weight: 400;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
    font-size: 16px;
}
.span-save{
    padding: 5px;
    color: red;
    font-size: larger;
    font-weight: 800;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
}
.flavour-btn{
    display: inline-block;
    box-sizing: border-box;
}
.flavour-label-checked{
    background: #dd0033;
    color: white;
    box-shadow: none !important;
    margin: 10px 4px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    padding: 7px 15px 7px;
    font-size: 16px;
}
.flavour-label-unchecked{
    background: #ffff;
    color: black;
    margin: 10px 4px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    padding: 7px 15px 7px;
    font-size: 16px;
    box-shadow: 0 0 0 1px #f4f4f4;
}
.flavour-input{
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
}
.p-class{
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
    font-size: 16px;
}
.fieldset-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: transparent;
    max-width: 450px;
    width: 100%;
    border: 0;
}
.selection-div{
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.selection-div > * {
    padding: 10px;
    flex: 1 100%;
  }
.selection-heading-div{
    display: flex;
    flex: 2 0 0;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    order: 1;
}
.selection-heading-div input{
    font-size: 1em;
    border-color: transparent;
    border-radius: 4px;
    background-color: #edf1f2;
    color: #4f5659;
    transition: opacity .4s ease-out;
    ont-family: soleil,sans-serif;
    line-height: 1.563;
    border: 1px solid #1c1d1d;
    font-weight: 400;
    margin: 0 10px 0 0;
    padding: 0;
}

.selection-heading-div-label{
    font-weight: bold;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
    font-size: 16px;
}

selection-heading-div-label-noafter{
    font-weight: bold;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
    font-size: 16px;
}

.selection-heading-div-label:after {
    content: "(1 Prolon + 30 Intermittent Fasting Bars)";
    display: block;
    font-weight: 100;
    font-size: 12px;
}

.selection-price-div{
    display: flex;
    flex: 1 0px;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    order: 2;
    color: #dd0033;
    font-weight: bold;
    font-size: 18px;
}
.selection-price-div s{
    font-size: 18px;
    font-weight: 100;
    padding: 0px 10px;
    color: black;
}
.selection-detail-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    order: 3;
    padding: 0px 30px;
}
.selection-detail-div select{
    appearance: none;
    background-image: url(//cdn.shopify.com/s/files/1/0126/2921/3243/t/69/assets/ico-select.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 11px;
    padding-right: 28px;
    text-indent: 0.01px;
    cursor: pointer;
    border: 1px solid;
    border-color: #f4f4f4;
    max-width: 100%;
    padding: 8px 10px;
    border-radius: 0;
    word-spacing: normal;
    text-transform: none;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    align-items: center;
    white-space: pre;
    margin: 0em;
    font: 400 13.3333px Arial;
}
.add-cart-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.add-cart-btn{
    max-width: 200px;
    overflow: hidden;
    padding: 13px 20px;
    line-height: 1.42;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #ffffff;
    background: #dd0133;
    // font-family: soleil,sans-serif;
    border:0;
}
`

const BuySection = () => {
    return (
        <StyledDiv>
            <div className="mainDiv">
                <div className="image-div"> 
                    <img className="image-class" src="//cdn.shopify.com/s/files/1/0126/2921/3243/products/ProLonSubscription_1800x1800.png?v=1600322185"  alt="ProLon®" />
                    <img className="image-class" src="//cdn.shopify.com/s/files/1/0126/2921/3243/files/Winter_Promo_540x.png?v=1607669569 540w" />
                </div>
                <div className="buy-box-main">
                    <div className="buy-box">
                        <h1 className="h1-class">ProLon®</h1>
                        <div>
                            <span className="span-old-price">$249.00</span>
                            <span className="span-new-price">$224.00</span>
                            <span className="span-save">Save $25.00</span>
                        </div>
                        <div>
                            <div className="flavour-btn">
                            <input 
                                type="radio" 
                                checked="checked" 
                                value="Original" 
                                data-index="option1" 
                                name="Soup Flavor" 
                                className="flavour-input" 
                                id="ProductSelect-4634645430318-option-soup-flavor-Original">
                            </input>
                            <label className="flavour-label-checked">Original</label>
                            </div>
                            <div className="flavour-btn">
                            <input 
                                type="radio" 
                                value="New Flavors" 
                                data-index="option2" 
                                name="Soup Flavor" 
                                className="flavour-input" 
                                id="ProductSelect-4634645430318-option-soup-flavor-Original">
                            </input>
                            <label className="flavour-label-unchecked">New Flavors</label>
                            </div>
                        </div>
                        <div>
                            <p className="p-class"><strong>Soups:</strong> Vegetable, Minestrone, Vegetable Quinoa,&nbsp;<br />Mushroom, Tomato</p>
                        </div>
                        <div className="fieldset-div">
                        <fieldset className="fieldset-div" name="Quantity" data-index="option2" id="prolon">
                            <div className="selection-div">
                                <div className="selection-heading-div">
                                    <input className="selection-heading-div-input" type="radio" checked="checked" value="Subscribe &amp; Save $25" data-index="option2" name="Quantity" id="ProductSelect-4634645430318-option-quantity-Subscribe+%26+Save+%2425"></input>
                                    <label for="Pro" className="selection-heading-div-label">Subscribe &amp; Save $25</label>
                                </div>
                                <div className="selection-price-div">
                                     <s>$249</s> $224
                                </div>
                                <div className="selection-detail-div">
                                     <select name="">  <option data-charge_interval_frequency="1" value="1" data-end="0">Deliver every month</option>  <option data-charge_interval_frequency="3" value="3" data-end="0">Deliver every 3 months</option></select>
                                </div>
                            </div>
                            <div className="selection-div">
                                <div className="selection-heading-div">
                                    <input className="selection-heading-div-input" type="radio" checked="checked" value="Subscribe &amp; Save $25" data-index="option2" name="Quantity" id="ProductSelect-4634645430318-option-quantity-Subscribe+%26+Save+%2425"></input>
                                    <label for="Pro" className="selection-heading-div-label">Subscribe &amp; Save $25</label>
                                </div>
                                <div className="selection-price-div">
                                     <s>$249</s> $224
                                </div>
                            </div>
                            <div className="selection-div">
                                <div className="selection-heading-div">
                                    <input className="selection-heading-div-input" type="radio" checked="checked" value="Subscribe &amp; Save $25" data-index="option2" name="Quantity" id="ProductSelect-4634645430318-option-quantity-Subscribe+%26+Save+%2425"></input>
                                    <label for="Pr" className="selection-heading-div-label-noafter">Subscribe &amp; Save $25</label>
                                </div>
                                <div className="selection-price-div">
                                     <s>$249</s> $224
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="add-cart-div">
                        <button type="submit" name="add" id="AddToCart-4634645430318" className="add-cart-btn">
                        <span id="AddToCartText-4634645430318" data-default-text="Add to cart">Add to cart</span>
                        </button>
                    </div>
                </div>
                </div>
            </div>

        </StyledDiv>

    )
}

export default BuySection